/* In your main CSS file */
@import "react-pdf/dist/esm/Page/AnnotationLayer.css";
@import "react-pdf/dist/esm/Page/TextLayer.css";
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playwrite+GB+S:ital,wght@0,100..400;1,100..400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.small-font {
  font-size: 12px;
}

.text-small {
  font-size: 14px;
}

.text-medium {
  font-size: 15px;
}

.font1 {
  font-family: "Raleway", Sans-serif;
}

.form-check-input {
  position: relative;
  margin: 0;
  padding: 0;
}

/* colors */
.primary-color {
  color: #0b3b5d;
}

.bg-grey {
  background-color: #f4f4f9;
}

.color-grey {
  color: lightgray;
}

.secondary-color {
  color: orange;
}

.setup-bg-color {
  background-color: #2e3192;
  color: #fff !important;
  transition: background-color 0.3s ease;
  border: none;
}

.setup-bg-color::marker {
  background-color: #2e3192; /* Darker shade on hover */
}

.setup-bg-color.btn:hover,
.setup-bg-color.btn:focus,
.setup-bg-color.btn:active {
  background-color: #0b3b5d !important;
}

.setup-bg-color:disabled {
  background-color: grey !important;
}

.red-shade {
  color: #8b0000;
}

.green-color {
  color: #a2c579;
}

.background-green {
  background-color: #a2c579;
  color: white;
}
/* special */

.border-none {
  border: none;
}

.cursor-pointer {
  cursor: pointer;
}

.nonestyle {
  list-style: none;
}

.hrStyle {
  width: 60%;
  margin: "0 auto";
  border-top: "1px solid #000";
}

hr {
  background-color: grey; /* You can adjust the border style and color as needed */
}

.custom-checkbox {
  width: 30px;
  height: 30px;
}

/* //for media controls */
@media print {
  .pdf-container {
    display: none;
  }
}

.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.react-pdf__Document {
  width: 100%;
  height: auto;
}

.react-pdf__Page {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.pdf-view-modelBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0;
}

/* background styles */

/* .bg-button-secondary {
  background-color: #a2c579;
  color: white;
} */
.bg-button-secondary {
  background-color: #ed9808;
  color: white;
}

.vertical-line {
  border-left: 1px solid #000; /* Change the color or style as needed */
  height: 100%; /* Adjust the height as needed */
}

.vertical-line-end {
  border-right: 1px solid lightgrey; /* Change the color or style as needed */
  height: 100%; /* Adjust the height as needed */
}

/* //all fonts ===
===============
=============== */
.font1 {
  font-family: "Raleway", Sans-serif;
}
.w-70 {
  width: 40vw;
}

.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}

.icon-size-1 {
  height: 28px;
  width: auto;
}

.icon-size {
  height: 32px;
  width: auto;
}

.icon-small {
  height: 24px;
  width: auto;
}

.icon-size-2 {
  height: 38px;
  width: auto;
}

.text-light {
  color: white !important;
}

.text-spacing {
  letter-spacing: 0.5rem;
}
.text-primary {
  color: #0b3b5d !important;
}

.text-support {
  color: #2c2392;
}

.navbarStyle {
  color: white;
  background-color: #0b3b5d;
}

/* navbar css */

.navbar {
  padding: 0;
}

.navbarLinks {
  font-weight: bold;
  color: white !important;
}

/* home page */

/* ==================
==================
DashBoard CSS==========
======================
================= */

.width-for-content {
  padding: 0vw 15vw;
}
.main-width {
  width: 75vw;
}

.regi3-width {
  width: 80vw;
}

.half-width {
  width: 30vw;
}

.slider-width {
  width: 29vw;
}

.sidebar-width {
  width: 20vw;
}

.cursive-font {
  font-family: "Raleway", sans-serif;
}

.Layout2-width {
  width: 49vw;
}

.dashbaordBody li {
  align-items: baseline;
  justify-content: space-between;
}

.element1 {
  display: flex;
  justify-content: space-between;
}

.QuickLinks {
  display: flex;
  justify-content: space-around;
}

.QuickLinksitem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: grey;
}

/* 
footer=====
==========
========== */
.footer-links {
  font-size: 1rem;
}
/* =================================
======== page not found css ======= */
.pnf {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnf-title {
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: black;
  border: 1px solid black;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}
.pnf-btn:hover {
  background-color: black;
  color: white;
}

/* for desktop view======
========================
======================= */

@media (max-width: 1050px) {
  .main-width {
    width: 90vw;
  }
  .icon-size {
    height: 3vw;
    width: auto;
  }
  .slider-width {
    width: 39.5vw;
  }
  .sidebar-width {
    width: 25vw;
  }
  .Layout2-width {
    width: 64vw;
  }

  .footer-links {
    font-size: 0.7rem;
  }
  .icon-size-2 {
    height: 24px;
    width: auto;
  }

  .desktop-image {
    height: 350px;
  }
}

/* 
//testing----------------------
=============================== */
.view-emp-style {
  position: relative;
}

.view-emp-id-row th,
.view-emp-id-row td {
  vertical-align: middle;
  text-align: center;
}

.view-emp-h2 th {
  vertical-align: middle;
}
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.pulse {
  animation: pulse 1s infinite;
}

.glow {
  box-shadow: 0 0 0 0 #007bff;
  animation: glow 1.4s ease-out infinite;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0 0 #007bff;
  }

  50% {
    box-shadow: 0 0 30px 0 #007bff;
  }
}

/* //special setup for new user interface classes */

.table-cell {
  background-color: #f2f2f2; /* Light grey background */
  border: none; /* No borders */
  padding: 10px; /* Optional padding */
}

.table-no-space td {
  padding: 0; /* Remove default padding */
  border-left: 0; /* Remove left border */
}

.table-no-space tr:first-child td {
  border-top: 0; /* Remove top border for the first row */
}

/* //for wallet resfres */
@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate-continuous {
  -webkit-animation: rotateAnimation 2s linear infinite;
  animation: rotateAnimation 2s linear infinite;
}

/* footer css  */

.footer-links a {
  font-family: "Raleway", Sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #4c4c4c !important;
}

/* .desktopFooter{
  width: 1222px;
} */

.dark-green-text {
  color: #255338;
  font-weight: 700;
  font-size: 16px;
  margin-top: 30px;
}

.desktop-headeraccount {
  position: absolute;
  right: 45px;
}

.header-brand {
  width: 48%;
}

@media screen and (max-width: 960px) {
  .header-brand {
    width: 100%;
    margin-block: 6px;
  }
}

.slick-track {
  display: flex !important;
  align-items: center !important;
}
.glowTexts {
  color: #000000; /* Dark text color */
  animation-name: glow;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes glow {
  from {
    text-shadow: 0px 0px 0px lightblue, 0px 0px 0px #bafffc;
  }
  to {
    text-shadow: 0px 0px 5px lightblue, 0px 0px 5px #bafffc;
  }
}

.glowText {
  color: Blue;
  animation-name: glowText;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes glowText {
  from {
    text-shadow: 0px 0px 5px #fff, 0px 0px 5px #353535;
  }
  to {
    text-shadow: 0px 0px 20px #fff, 0px 0px 20px #353535;
  }
}


.step-container {
  position: relative;
  text-align: center;
  transform: translateY(-35%);
}

.step-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #007bff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.step-label{
  font-size: 12px;
  text-wrap: nowrap;
}






/* video chat css  */

/* room wrapper */

.roomWrapper {
  background-color: #0c0e10;
  width: 100vw;
  height: 100vh;
}

/* Controls */

.controlsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.cntBtn {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #42b883;
  border: none;
  border-radius: 10px;
}
.cntBtn span {
  font-size: 36px;
  color: whitesmoke;
}
.leave {
  background-color: #eb2632;
}
.cntBtn button {
  font-size: 23px;
  color: whitesmoke;
  padding: 0;
}

/* header */
.headerWrapper {
  display: flex;
  align-items: center;
  padding: 20px;
}
.headerWrapper .logoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 40px;
}
.headerWrapper .logoWrapper img {
  width: 110px;
  height: 40px;
}
.headerWrapper .roomInfo {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  align-items: center;
  gap: 10px;
  flex: 1;
  color: whitesmoke;
}
.headerWrapper .roomInfo .copy {
  background-color: #447960;
  color: whitesmoke;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 5px;
  transition: 0.5s ease;
}
.headerWrapper .roomInfo .copy:active {
  transform: scale(0.7);
}
.headerWrapper .roomInfo .copy span {
  font-size: 24px;
}

/* middleSection */
.middleSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 70vh;
  padding: 0 20px;
}

/* side controls */
.sideControlsWrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
}
.sideControlsWrapper span {
  color: whitesmoke;
  font-size: 40px;
  cursor: pointer;
}

/* sidePanelWrapper */
.sidePanelWrapper {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100%;
  background-color: #9cf5c2;
  border-radius: 10px;
  transition: all 1s ease;
}
.sidePanelWrapper .top {
  padding: 5px;
  background-color: #2c9158;
  border-radius: 10px 10px 0 0;
  padding-left: 15px;
  color: whitesmoke;
}
.sidePanelWrapper .panel {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column-reverse;
  gap: 5px;
}
.sidePanelWrapper .panel .message {
  background-color: #42b883;
  border-radius: 10px;
  border: none;
  padding: 5px;
}
.sidePanelWrapper .panel .person {
  font-weight: 400;
  padding: 10px;
  border-radius: 10px;
  background-color: #42b883;
  font-size: 24px;
  align-self: center;
}
.sidePanelWrapper .panel .self {
  background-color: grey;
}
.sidePanelWrapper .inputPanel {
  padding: 10px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 5px;
}
.sidePanelWrapper .inputPanel .send {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #0b8f54;
  padding: 5px;
  background-color: #0b8f54;
}
.sidePanelWrapper .inputPanel .send:hover {
  box-shadow: 0 0 10px #0b8f54;
}
.sidePanelWrapper .inputPanel .send span {
  font-size: 30px;
  color: whitesmoke;
}
.sidePanelWrapper .inputPanel .messageInput {
  flex: 1;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  padding: 5px;
  box-shadow: 0 0 4px rgb(0, 0, 0);
}
.sidePanelWrapper .inputPanel .messageInput:focus {
  box-shadow: 0 0 14px #0b8f54;
  outline: none;
}
.sidePanelWrapper .inputPanel .fileInput {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #21864d;
  font-size: 20px;
  padding: 5px;
  background-color: white;
  border-radius: 50%;
  /* box-shadow: 0 0 4px white; */
  box-shadow: 0 0 4px rgb(0, 0, 0);
  cursor: pointer;
}
.downBtn {
  border: none;
  display: flex;
  border-radius: 10px;
}

/* videosSection */
.videosSectionWrapper {
  height: 70vh;
  flex: 1;
  /*overflow-y: auto;
  margin: 0 20px;*/
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the gap between boxes as needed */
  overflow-y: auto; /* Enable vertical scrolling when needed */
}
.videoPlayerWrapper {
  padding: 10px;
  border: 4px solid #42b883;
  border-radius: 20px;
  flex: 1 1 200px;
  min-width: 350px;
  min-height: 200px;
  max-width: 600px;
  max-height: 65vh;
  background-color: lightblue;
  margin: 5px;
}
.videoPlayerWrapper h6 {
  color: #1b3e2e;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
}
.videoPlayerWrapper video {
  width: 100%;
  height: 92%;
}
.videoBottom {
  display: flex;
  justify-content: space-between;
}
.fullScreen {
  font-weight: bold;
  cursor: pointer;
  color: #1b3e2e;
}

/* createRoomWrapper */
.createRoomWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 20px;
  /* background-image: url("/public/green.jpg"); */
}
.createRoomWrapper input {
  padding: 10px;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 6px black;
}
.createRoomWrapper button {
  border: 1px solid whitesmoke;
  background-color: whitesmoke;
  color: #0b8f54;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
}
.createRoomWrapper button:hover {
  box-shadow: 0 0 8px whitesmoke;
}





/* our presence */
@media (max-width: 768px) {
  .country-item {
      min-width: 80px !important;
      max-width: 100px !important;
  }

  .country-item span {
      font-size: 0.8rem !important;
  }

  .our-presence .container-fluid {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
  }
}

@media (max-width: 576px) {
  .country-item {
      min-width: 70px !important;
      max-width: 90px !important;
  }
}



