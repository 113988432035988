.custom-width {
  width: 100%;
}

.bg-color {
  background-color: #0b3b5d;
  color: white;
}

.text-primary:hover {
  color: red;
  cursor: pointer;
}

.btn.bg-color {
  pointer-events: none;
}
