@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.log1-body {
  padding: 0rem 15rem;
}

.reg1-email {
  gap: 20;
}

.ml-1 {
  margin-left: 1rem;
}

.bg-color-2 {
  background-color: #eb9b00;
  color: white;
  border: none;
}

.container-form {
  padding: 1rem 4rem;
  margin-top: 1rem;
  background-color: #0b3b5d;
  color: white;
}

.update-review-input {
  border-radius: 0.2rem;
  font-weight: 600;
  border: none;
  font-size: small;
  padding: 0.5rem 2rem;
}

.container-button {
  display: flex;
}

.container-button span {
  font-size: small;
}

/* //register-3=====
================
================ */


.montserratFont {
    font-family: "Montserrat", sans-serif;
}


.montserratAlternatesFont {
  font-family: "Montserrat Alternates", sans-serif;
}



.select-custom-font-size {
  font-size: 0.9rem; 
}


.custom-width {
    width: 75vw;
}


@media (max-width: 770px) {
  .custom-width {
    width: 90vw;
  }
}