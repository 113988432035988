.masonry-grid {
    column-count: 3; /* Number of columns */
    column-gap: 0.8rem; /* Space between columns */
  }
  
  @media (max-width: 1100px) {
    .masonry-grid {
      column-count: 3; /* Number of columns for medium screens */
    }
  }
  
  @media (max-width: 700px) {
    .masonry-grid {
      column-count: 2; /* Number of columns for small screens */
    }
  }
  
  @media (max-width: 500px) {
    .masonry-grid {
      column-count: 1; /* Single column for very small screens */
    }
  }
  
  .masonry-item {
    break-inside: avoid;
    margin-bottom: 1rem;
  }
  



    .custom-nav .nav-item-custom .nav-link {
      color: black;
      position: relative;
      padding-bottom: 10px;
    }
  
    .custom-nav .nav-item-custom .nav-link.active {
      color: black;
      /* or any color you prefer */
      font-weight: bold;
    }
  
    .custom-nav .nav-item-custom .nav-link.active::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      left: 0;
      bottom: 0;
      background-color: blue;
      /* The color of the underline */
      border-radius: 5px;
    }
  
    .custom-nav .nav-item-custom .nav-link:hover {
      color: blue;
    }