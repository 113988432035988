.booklibaray {
  background-color: #eaeef4;
  width: 100%;
}

.book-card {
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  /* box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; */
  border-radius: 5px;
}

.book-name {
  font-size: calc(0.2em + 0.5vw);
  font-weight: 700;
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-image-category {
  border: 0;
}

.book-card-category {
  transform: scale(1);
  border: 0 !important;
  transition: transform 0.3s ease-in-out;
}

.book-card-category:hover {
  transform: scale(1.2); 
  transition: transform 0.3s ease-in-out; 
  /* box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11); */
}

.book-card-category .book-name {
  font-size: 10px;
}


.smaller-nav-pills .nav-link {
  padding: 0.2rem 0.6rem; 
  font-size: 0.77rem; 
}

/* Default state */
.smaller-nav-pills .nav-link {
  background-color: #e5eefd; 
  color: #000;
  transition: background-color 0.3s ease, color 0.3s ease; 
  border-radius: 25px;
}

.smaller-nav-pills .nav-link:hover {
  background-color: #007bff; 
  color: #fff; 
}


.smaller-nav-pills .nav-link:active {
  background-color: #007bff; 
  color: #fff; 
}

.offcanvas-body {
  width: 490px;
}


