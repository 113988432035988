* {
    margin: 0;
    padding: 0;
}

.s-soft {
    position: fixed;
    top: 200px;
    left: 10;
    z-index: 1000;
    transition: all linear 0.2s;
}

.s-soft a:first-child {
    border-radius: 0 5px 0 0;
}

.s-soft a:last-child {
    border-radius: 0 0 5px 0;
}


.s-soft a:hover{
    color: white;
}

.s-item {
    display: block;
    width: 60px;
    height: 60px;
    color: white;
    font-size: 25px;
    line-height: 60px;
    text-align: center;
    transition: all linear 0.2s;
    font-size: 20px;
}

.s-item:hover {
    width: 70px;
    border-radius: 0px 0px 0px 0px;
}

#so-open {
    position: fixed;
    top: 100px;
    left: -40px;
    border-radius: 0 30px 30px 0;
    transition: all linear 0.2s;
}


.facebook {
    background-color: #3b5999;
}

.twitter {
    background-color: #3AAFD6;
}

.youtube {
    background-color: #e51b1b;
}

.linkedin {
    background-color: #0e76a8;
}

.instagram {
    background-color: #e878e8;
}